import React from 'react'
import { graphql } from 'gatsby'
import TransitionLink from 'gatsby-plugin-transition-link'

import Main from '@templates/Main'
import Button from '@atoms/Button'
import TextBox from '@atoms/TextBox'
import SectionGrayMiddle from '@molecules/SectionGrayMiddle'
import SignupCTA from '@organisms/SignupCTA'
import FooterCTA from '@organisms/FooterCTA'

const CookiesPage = ({ data, transitionStatus }) => {
  const pageData = data.pageData

  return (
    <Main seo={pageData.seoMetaTags} transitionStatus={transitionStatus}>
      <SectionGrayMiddle hero>
        <TextBox title="Error 404" center>
          <h2>Whoopsy</h2>
          <p>We're sorry but it's a dead end</p>
          <TransitionLink
            to="/"
            title="Back to home page"
            exit={{
              length: 0.3,
            }}
            entry={{
              delay: 0.3,
            }}
          >
            <Button size="L" color="primary">
              Back to home page
            </Button>
          </TransitionLink>
        </TextBox>
      </SectionGrayMiddle>
      <SignupCTA bgColor="gray" />
      <FooterCTA />
    </Main>
  )
}

export const query = graphql`
  query ErrorPageQuery {
    pageData: datoCmsErrorPage {
      seoMetaTags {
        tags
      }
    }
  }
`

export default CookiesPage
