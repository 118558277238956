import React from 'react'
import PropTypes from 'prop-types'
import TransitionLink from 'gatsby-plugin-transition-link'

import CTA from '@molecules/CTA'
import Button from '@atoms/Button'
import TextBox from '@atoms/TextBox'

const FooterCTA = ({ active }) => {
  return (
    <CTA>
      <TextBox>
        <h2>
          Want to know more?
          <br />
          Don't hesitate to contact us.
        </h2>
        <TransitionLink
          to="/contact"
          title="Contact us"
          exit={{
            length: 0.3,
          }}
          entry={{
            delay: 0.3,
          }}
        >
          <Button size="L" color="primary">
            Go to contact page
          </Button>
        </TransitionLink>
      </TextBox>
    </CTA>
  )
}

FooterCTA.propTypes = {
  active: PropTypes.bool,
}

FooterCTA.defaultProps = {
  active: false,
}

export default FooterCTA
